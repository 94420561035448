<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4" style="font-size: 16pt; font-weight: bold">NOTE IMPORTANTE</p>

      <p class="mb-4" style="font-size: 11pt">
        Veuillez imprimer l’Exercice au Laboratoire pour l’Expérience 5 et compléter toutes les
        questions avant de venir à votre séance de laboratoire. Apportez votre exercice complet au
        laboratoire avec du papier graphique ou avec un ordinateur qui contient un logiciel qui peut
        faire des graphiques pour être capable de travailler sur l’exercice au laboratoire avec les
        membres de votre équipe.
      </p>

      <p class="mb-5" style="font-size: 11pt; text-align: justify">
        Pour reconnaître que vous avez lu cette tâche et acceptez de suivre les instructions
        ci-dessus, veuillez cliquer sur le bouton "J'accepte" ci-dessous, puis cliquer sur le bouton
        "Save Response".
      </p>

      <div v-for="option in options" :key="option.value">
        <v-checkbox
          v-model="inputs.input1"
          :disabled="!allowEditing"
          class="d-inline-block"
          :value="option.value"
        />
        <stemble-latex :content="option.expression" />
      </div>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'UOttawaNewPrelabQ5',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: [],
      },
      options: [
        {
          expression: "J'accepte",
          value: 'agreed',
        },
      ],
    };
  },
};
</script>
